declare let Savane;

import { ARRMGR_URL, ARRMGR_TOKEN } from './Init';

import { ArrangementObject, ComponentConstants, HttpService, math } from '@rhinov/savane-js';

let replaceWithPopulatedObject = (oldEntity: ArrangementObject, arrayLayouts: Array<any>, index: number, oldObjects: Array<any>, newObjectsAndParents: Array<any>) => {
    if (!oldEntity.parent || !oldEntity.floor) {
        return;
    }

    let group;

    // Ancien format à supprimer
    if (!arrayLayouts[index].format) {
      group = Savane.EntityFactory.createArrangementGroupFromJSON(arrayLayouts[index]);
    }
    else {
      // Nouveau format a garder
      group = Savane.EntityFactory.createArrangementGroupFromJSON(arrayLayouts[index].format);
    }
    group.AMLayoutId = arrayLayouts[index]._id;

    // Keep track of the original object ID (to be able to find it in the created group)
    if (oldEntity.masterObjectId) {
        group.masterObjectId = oldEntity.masterObjectId;
    }
    else {
        group.masterObjectId = oldEntity.objectId;
    }
    // Find master object into previous entity
    let srcMasterObjectIndex = -1;
    for (let i = 0; i < oldEntity.children.length; i++) {
        if ((oldEntity.children[i] as ArrangementObject).objectId === group.masterObjectId) {
            srcMasterObjectIndex = i;
            break;
        }
    }

    // Find master object into new group
    let destMasterObjectIndex = -1;
    for (let i = 0; i < group.children.length; i++) {
        if (group.children[i].objectId === group.masterObjectId) {
            destMasterObjectIndex = i;
            break;
        }
    }

    // Keep original entity id into new created group
    if (srcMasterObjectIndex >= 0) {
        if (destMasterObjectIndex >= 0) {
            group.children[destMasterObjectIndex].id = oldEntity.children[srcMasterObjectIndex].id;
        }
    }
    else {
        if (destMasterObjectIndex >= 0) {
            group.children[destMasterObjectIndex].id = oldEntity.id;
        }
    }
    // End override entity id

    // Copy customizations is any
    let oldEntityCustomization = oldEntity.customization;
    // No customization so deep search because oldEntity might be an arrangement group
    if (!oldEntityCustomization && srcMasterObjectIndex >= 0) {
        oldEntityCustomization = (oldEntity.children[srcMasterObjectIndex] as ArrangementObject).customization;
    }
    // If a customization is available, let's clone it
    if (oldEntityCustomization && destMasterObjectIndex >= 0) {
        group.children[destMasterObjectIndex].customization = JSON.parse(JSON.stringify(oldEntityCustomization));
    }
    // Copy customization coatings
    let oldEntityCoatings = oldEntity.getComponents(ComponentConstants.ComponentType.Coating);
    // No coating so deep search because oldEntity might be an arrangement group
    if (oldEntityCoatings.length === 0 && srcMasterObjectIndex >= 0) {
        oldEntityCoatings = oldEntity.children[srcMasterObjectIndex].getComponents(ComponentConstants.ComponentType.Coating);
    }
    // Assign coatings to object into the group
    if (destMasterObjectIndex >= 0) {
        for (let i = 0; i < oldEntityCoatings.length; i++) {
            group.children[destMasterObjectIndex].addComponent(oldEntityCoatings[i].clone());
        }
    }
    // If there are children, copie them
    let oldEntityChildren = oldEntity.children;
    if (srcMasterObjectIndex >= 0) {
        oldEntityChildren = oldEntity.children[srcMasterObjectIndex].children;
    }
    // Assign children to object into the group
    if (destMasterObjectIndex >= 0) {
        for (let i = 0; i < oldEntityChildren.length; i++) {
            group.children[destMasterObjectIndex].addChild(Savane.EntityFactory.cloneEntity(oldEntityChildren[i], true));
        }
    }
    // Copy special parameters into dest entity
    let oldEntityHandleSide = oldEntity.handleSide;
    let oldEntityHandleAsset = oldEntity.handleAsset;
    if (srcMasterObjectIndex >= 0) {
        oldEntityHandleSide = (oldEntity.children[srcMasterObjectIndex] as ArrangementObject).handleSide;
        oldEntityHandleAsset = (oldEntity.children[srcMasterObjectIndex] as ArrangementObject).handleAsset;
    }
    if (destMasterObjectIndex >= 0) {
        group.children[destMasterObjectIndex].handleSide = oldEntityHandleSide;
        group.children[destMasterObjectIndex].handleAsset = oldEntityHandleAsset;
    }
    // To remove original object scale
    let res = math.vec3.create();
    math.vec3.set(res, 1, 1, 1);
    // Copy original object matrix
    if (srcMasterObjectIndex >= 0) {
        math.mat4.copy(group.transform.localMatrix, oldEntity.children[srcMasterObjectIndex].transform.globalMatrix);
    }
    else {
        math.mat4.copy(group.transform.localMatrix, oldEntity.transform.globalMatrix);
    }
    // remove original object scale if any
    group.transform.localScale = res;
    // Save group altervatives
    group.masterObjectLayouts = arrayLayouts;
    // Save current alternative number
    group.masterObjectCurrentLayout = index;

    group.recenter();

    oldObjects.push(oldEntity);
    newObjectsAndParents.push({ entity: group, parent: oldEntity.parent });
};

export let loadPopulatedObjects = (entity: ArrangementObject, oldObjects: Array<any>, newObjectsAndParents: Array<any>, callback: CallableFunction) => {
    let url = ARRMGR_URL + 'api/v1/layouts?q.master.eq=' + entity.objectId;

    //if (isForB2B()) {
        url += '&unrealOnly=1';
    //}

    if (entity.stretchability) {
        url += '&q.dimensions.width.bt=' + (entity.length - 50) + ',' + (entity.length + 50) +
            '&q.dimensions.length.bt=' + (entity.width - 50) + ',' + (entity.width + 50) +
            '&q.dimensions.height.bt=' + (entity.height - 3) + ',' + (entity.height + 3);
    }
    else {
        url += '&q.dimensions.width.bt=' + (entity.length - 1) + ',' + (entity.length + 1) +
            '&q.dimensions.length.bt=' + (entity.width - 1) + ',' + (entity.width + 1) +
            '&q.dimensions.height.bt=' + (entity.height - 1) + ',' + (entity.height + 1);
    }

    url += '&format=genericsAndReferences&apikey=' + ARRMGR_TOKEN;

    HttpService.get(url)
        .then(
            function(response) {
                //success
                if (response.status === 200 && response.data && response.data.resources && response.data.resources.length > 0) {
                    replaceWithPopulatedObject(entity, response.data.resources, 0, oldObjects, newObjectsAndParents);
                }

                let displayWarning = false;
                if (response.status === 204) {
                    displayWarning = true;
                }

                callback(displayWarning);
            }.bind(this),
            function(response) {
                // failure
                callback(false);
            }
        );
};

export let switchPopulatedObjects = (entity: ArrangementObject, add: number, oldObjects: Array<any>, newObjectsAndParents: Array<any>, callback: CallableFunction) => {
    let displayWarning = false;
    if (entity.masterObjectLayouts !== undefined) {
        if (entity.masterObjectLayouts.length > 1) {
            let configNb = entity.masterObjectCurrentLayout + add;
            if (configNb >= entity.masterObjectLayouts.length) {
                configNb = 0;
            }
            if (configNb < 0) {
                configNb = entity.masterObjectLayouts.length - 1;
            }
            replaceWithPopulatedObject(entity, entity.masterObjectLayouts, configNb, oldObjects, newObjectsAndParents);
        }
        else {
            displayWarning = true;
        }
    }

    callback(displayWarning);
};
