import { ARRMGR_URL, ARRMGR_API_KEY } from './Init';

import { HttpService } from '@rhinov/savane-js';

/**
 * Retrieve tag match with input
 *
 * @param inputString
 * @param returnCount
 * @param callback
 */
export let getTagsMatching = (inputString: string, returnCount: string, callback: CallableFunction) => {
    HttpService.get(ARRMGR_URL + 'api/v1/tags?q.name.lk=' + inputString + '&page=1&limit=' + returnCount + '&' + ARRMGR_API_KEY)
        .then(
            function(response) {
                //success
                callback(response);
            },
            function(response) {
                //error
                callback(null);
            }
        );
};