import { ARRMGR_URL, ARRMGR_API_KEY } from './Init';

import { HttpService } from '@rhinov/savane-js';

export let getTemplates = (styles: Array<string>, roomtypes: Array<string>, stringFilters: string, page: string, limit: string, callback: CallableFunction) => {
    let url = ARRMGR_URL + 'api/v1/templates?' + ARRMGR_API_KEY + stringFilters;
    if (styles.length > 0) {
        url += '&q.styles.in=' + styles;
    }
    if (roomtypes.length > 0) {
        url += '&q.roomTypes.in=' + roomtypes;
    }
    HttpService.get(url + '&page=' + page + '&limit=' + limit)
        .then(
            function(response) {
                callback(response.data);
            },
            function() {
                callback(null);
            }
        );
};