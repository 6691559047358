import { ArrangementObject, HttpService } from '@rhinov/savane-js';

// Path to assets
export let ARRMGR_URL = '//assetmanager.rhinovplanner.com/';
export let ARRMGR_TOKEN = '$2a$10$H/D4EyQxl1myXixtuuvAT.Sc1nG.mUT8Mmnfmp9xI1GeYWOTwqzqC';
export let ARRMGR_API_KEY = 'apikey=' + ARRMGR_TOKEN;
export let ARRMGR_COUNTRY_PARAM = '&q.locales.fr_FR.availability.eq=fully_available';
export let ARRMGR_COUNTRY_ID = 0;
export let ARRMGR_FRONT_URL = '//am.rhinovplanner.com/';
export let ARRMGR_MEDIA_URL = '//media.rhinov.fr/';
export let ARRMGR_FOR_B2B = false;

let init_to_cloud = (media: boolean) => {
    if (!media) {
        // Fail to connect, switch to cloud asset manager only if prod
        let index = ARRMGR_URL.indexOf("assetmanager.rhinov.fr");
        if (index !== -1) {
            ARRMGR_URL = ARRMGR_URL.slice(0, index) + 'cloud-' + ARRMGR_URL.slice(index);
            console.warn('Connected to cloud asset manager');
        }
    }
    else {
        ARRMGR_MEDIA_URL = ARRMGR_URL + 'api/v1/';
    }
}

/**
 * reset asset manager url ,token
 */
export let init = (url: string, front_url: string, media_url: string, token: string) : void => {
    ARRMGR_URL = url;
    ARRMGR_FRONT_URL = front_url;
    ARRMGR_MEDIA_URL = media_url;
    ARRMGR_TOKEN = token;
    ARRMGR_API_KEY = 'apikey=' + ARRMGR_TOKEN;

    // Test AssetManager DB connexion with NAE BLANC
    HttpService.get(ARRMGR_URL + 'api/v1/coatings/5d4a9f07fcef3b63c55e79d5?' + ARRMGR_API_KEY)
        .then(function(response) {
        }, function (error) {
            // Fail to connect, switch to cloud asset manager only if prod
            init_to_cloud(false);
        });

    // Test AssetManager media connexion with NAE BLANC
    let http = new XMLHttpRequest();
    try {
        http.open('HEAD', ARRMGR_MEDIA_URL + 'coatings/5d4a9f07fcef3b63c55e79d5/medias/previews/preview1-sm.webp', false);
        http.send();

        // Success keep current AM URL
        if (http.status !== 200) {
            // Fail to connect, switch to cloud asset manager only if prod
            init_to_cloud(true);
        }
    }
    catch(e) {
        // Fail to connect, switch to cloud asset manager only if prod
        init_to_cloud(true);
    }
};

export let setForB2B = (value: boolean) => {
    ARRMGR_FOR_B2B = value;
}

export let isForB2B = (): boolean => {
    return ARRMGR_FOR_B2B;
}

/**
 * reset asset manager country
 */
export let initCountry = (customer_groups_id: number) : void => {
    ARRMGR_COUNTRY_ID = customer_groups_id;
    switch(ARRMGR_COUNTRY_ID) {
        case 176:
            ARRMGR_COUNTRY_PARAM = '&q.locales.it_IT.availability.eq=fully_available';
            break;

        case 175:
            ARRMGR_COUNTRY_PARAM = '&q.locales.es_ES.availability.eq=fully_available';
            break;

        case 177:
            ARRMGR_COUNTRY_PARAM = '&q.locales.de_DE.availability.eq=fully_available';
            break;

        default:
            ARRMGR_COUNTRY_PARAM = '&q.locales.fr_FR.availability.eq=fully_available';
    }
};

console.warn('If there is no contradiction message: CocosConstants are used to load asset manager service config,Default is recette');

export let setToken = (token: string) : void => {
    ARRMGR_TOKEN = token;
    ARRMGR_API_KEY = 'apikey=' + ARRMGR_TOKEN;
};

/**
 * getter for conf
 */
export let getUrl = () : string => {
    return ARRMGR_URL;
};

export let getFrontUrl = () : string => {
    return ARRMGR_FRONT_URL;
};

export let getMediaUrl = () : string => {
    return ARRMGR_MEDIA_URL;
};

export let getMediaArrangementUrl = () : string => {
    if (ARRMGR_MEDIA_URL.indexOf("media") === -1) {
        return ARRMGR_MEDIA_URL + "assets/";
    } else {
        return ARRMGR_MEDIA_URL + "arrangements/";
    }
};

export let getUrlAssets = () : string => {
    return ARRMGR_URL + 'api/v1/assets/';
};

export let getToken = () : string => {
    return ARRMGR_TOKEN;
};

export let getApiKey = () : string => {
    return ARRMGR_API_KEY;
};

export let getSmartDesignerUrl = () : string => {
    return ARRMGR_URL;
}

export let resetUrl = (arrangement: ArrangementObject, url: string) => {
    let idx = url.indexOf("coatings");

    if (idx !== -1) {
        return(getMediaUrl() + "coatings/" + arrangement.objectId + "/medias/previews/preview" + arrangement.colorId + "-sm.webp");
    }
    else {
        return(getMediaArrangementUrl() + arrangement.objectId + "/medias/previews/top_view" + arrangement.colorId + ".webp");
    }
}