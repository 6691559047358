// All asset types within the asset manager
export enum _ASSET_TYPE {
    ARRANGEMENTS = 'assets',
    COATINGS = 'coatings',
    TEMPLATES = 'assets_groups',
    TAGS = 'tags',
    JOINERIES = 'joineries',
    TECHNICAL_ELEMENTS = 'technicalElements',
    DECORATED_WALLS = 'decoratedwalls',
    ALL = 'allAssets'
};

// Routes to access asset sub types
export enum _ROUTE_PARAMS {
    ROOM_TYPES = 'roomtypes',
    OBJECT_TYPES = 'objecttypes',
    STYLES = 'styles',
    TAGS = 'tags',
    JOINERY_TYPES = 'joinerytypes',
    TECHNICAL_ELEMENT_TYPES = 'technicalelementtypes',
    DECORATED_WALL_TYPES = 'decoratedwalltypes'
};

// Asset creation event broadcasted from the asset manager loader
export enum EVENTS {
    ARRANGEMENT_CREATED = 'assetmanager_arrangement_created'
};