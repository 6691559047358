declare let Savane;
declare let EntityDefaultPropertiesManager;

import { ARRMGR_URL, ARRMGR_MEDIA_URL, ARRMGR_API_KEY, ARRMGR_TOKEN, getMediaArrangementUrl } from './Init';
import { _ASSET_TYPE, EVENTS } from './Constants';
import { isKitchenEntityWithPlinths } from './Kitchen';

import { Coating, ComponentConstants, SceneConstants, math, HttpService } from '@rhinov/savane-js';

import async from 'async-es';

/**
 *
 * @param assetType
 * @param stringFilters
 * @param callback
 * @returns {*}
 */
export let getAssets = (assetType: string, stringFilters: string, callback: CallableFunction) => {
    HttpService.get(ARRMGR_URL + 'api/v1/' + assetType + '?' + ARRMGR_API_KEY + stringFilters)
        .then(
            function(response) {
                if (callback)
                    callback(response, true);
                return (response);
            },
            function(response) {
                if (callback)
                    callback(response, false);
            }
        );
};

/**
 * Retrieve assets filtered
 *
 * @param assetType
 * @param filters
 * @param page
 * @param limit
 * @param callback
 * @returns {*}
 */
export let getAssetsFiltered = (assetType: string, filters: Array<any>, page: number, limit: number, callback: CallableFunction) => {
    // Filter we will send in the web service call
    let filter;
    //Base params with page number and result per page
    let requestParams = 'page=' + page + '&limit=' + limit;

    // Read filter and bind to request format
    for (filter in filters) {
        // Get current filter
        let currentFilter = filters[filter];

        if (currentFilter.key && currentFilter.method && currentFilter.values !== undefined) {
            if (currentFilter.values !== null) {
                //A value set to null mean no filter on that field
                let filterName = currentFilter.filter ? currentFilter.filter : filter;
                //If no filter set use default field name
                let filterConcat = '&q.' + filterName + '.' + currentFilter.key + '.' + currentFilter.method + '=' + currentFilter.values;
                requestParams += filterConcat;
            }

        } else {
            if (currentFilter.values !== null) {
                console.error('Wrong format for filter : ' + filter);
            }
        }
    }

    //Send request
    HttpService.get(ARRMGR_URL + 'api/v1/' + assetType + '/?' + requestParams + '&' + ARRMGR_API_KEY)
        .then(
            function(response) {
                if (callback)
                    callback(response);
                return (response.data);
            },
            function(response) {
                if (callback)
                    callback(response);
            }
        );
};

/**
 * Retrieve asset datas
 * @param assetType
 * @param id
 * @param apiKey
 * @param callback
 * @returns {*}
 */
export let getAsset = (assetType: _ASSET_TYPE, id: string, apiKey: string | null, callback: CallableFunction) => {
    let OPTIMIZED_DB_FIELDS = '&fields=name,randomization,isHpFloorGenerator,hpFloorGeneratorSettings,dimensions,sketchBlock,_id,configs,colors,stretchability,objectType,coatingType,hang,technicalElementType,manufacturer,retailer,joineryType,styles,objectTypeConfig,zones,customization,type,rhinovFormat';

    HttpService.get(ARRMGR_URL + 'api/v1/' + assetType + '/' + id + '?' + OPTIMIZED_DB_FIELDS + '&' + ARRMGR_API_KEY)
        .then(
            function(response) {
                if (callback)
                    callback(response.data);
            },
            function(response) {
                if (callback)
                    callback(response);
            }
        );
};

export let getHandles = (filter: string, objectType: string, callback: CallableFunction) => {
    HttpService.get(ARRMGR_URL + 'api/v1/assets?q.objectType._id.in=' + objectType + '&' + filter + '&' + ARRMGR_API_KEY)
        .then(function(response) {
            callback(response);
        });
};

export let createAssetEntity = (assetType: _ASSET_TYPE, selectedAsset: any, loadExtraAssets: boolean, callback: CallableFunction) => {
    if (!selectedAsset || !selectedAsset._id) {
        return;
    }

    if (assetType === _ASSET_TYPE.TEMPLATES) {
        let format = selectedAsset.rhinovFormat;
        if (!format || !format.children) {
            return;
        }

        let arrangementGroup = Savane.EntityFactory.createArrangementGroupFromJSON(format);
        arrangementGroup = Savane.EntityFactory.cloneArrangementGroup(arrangementGroup, true);

        arrangementGroup.recenter();
        arrangementGroup.objectId = selectedAsset._id;
        arrangementGroup.isAnchorActive = false;
        callback(arrangementGroup);
    }
    else if (assetType === _ASSET_TYPE.ARRANGEMENTS || assetType === _ASSET_TYPE.TECHNICAL_ELEMENTS ||
        assetType === _ASSET_TYPE.JOINERIES || assetType === _ASSET_TYPE.COATINGS ||
        assetType === _ASSET_TYPE.DECORATED_WALLS) {
        let i, coating = null;

        if ((selectedAsset.configs !== undefined) && (selectedAsset.configs !== null)) {
            for (i = 0; i < selectedAsset.configs.length; i++) {
                if (selectedAsset.choosenIndex === selectedAsset.configs[i].index) {
                    coating = (selectedAsset.configs[i].coating !== null && selectedAsset.configs[i].coating !== undefined &&
                        selectedAsset.configs[i].coating.item !== null && selectedAsset.configs[i].coating.item !== undefined &&
                        selectedAsset.configs[i].coating.item._id.length > 0) ? selectedAsset.configs[i].coating.item._id : null;
                    break;
                }
            }
        }

        // Entity created and to return
        let entity;

        switch (assetType) {
            case _ASSET_TYPE.ARRANGEMENTS: {
                let URL = getMediaArrangementUrl() + selectedAsset._id + '/medias/previews/top_view1.webp';
                let choosenIndex = 1;

                if (selectedAsset.choosenIndex !== undefined) {
                    URL = getMediaArrangementUrl() + selectedAsset._id + '/medias/previews/top_view' + selectedAsset.choosenIndex + '.webp';
                    choosenIndex = selectedAsset.choosenIndex;
                }

                // Build customization for kitchen element, dressing elements, bathroom elements (for instance)
                let customization: { parts: Array<any>, expectedParts: Array<any>, customizationGroup: any } | undefined = { parts: new Array<any>(), expectedParts: new Array<any>(), customizationGroup: {} };

                if (selectedAsset.customization !== undefined) {
                    // Save customization parts
                    if (selectedAsset.customization.parts) {
                        for (i = 0; i < selectedAsset.customization.parts.length; i++) {
                            let type = selectedAsset.customization.parts[i].name.split('_')[2];
                            let id_coating = selectedAsset.customization.parts[i].id_coating;

                            if ((id_coating === undefined) || (id_coating === '')) {
                                if (typeof EntityDefaultPropertiesManager === 'undefined') {
                                    id_coating = '664e0d00b751b3c903290f2c';
                                }
                                else {
                                    if (type === "Porte") {
                                        let j = 0
                                        for (; j < selectedAsset.customization.expectedParts.length ; j++) {
                                            if (selectedAsset.customization.expectedParts[j].name === "Porte") {
                                                break;
                                            }
                                        }

                                        if (EntityDefaultPropertiesManager.getInstance().isCoatingCompatibleWithDoorCustomizationGroups(selectedAsset.customization.expectedParts[j].groups)) {
                                            id_coating = EntityDefaultPropertiesManager.getInstance().getDefaultCoating(type, selectedAsset.manufacturer.name, selectedAsset.objectType._id);
                                        }
                                        else {
                                            if (selectedAsset.manufacturer.name === 'Lapeyre') {
                                                id_coating = '670e87fe970bd168a0d72118';
                                            }
                                            else {
                                                id_coating = '664e0d00b751b3c903290f2c';
                                            }
                                        }
                                    }
                                    else {
                                        id_coating = EntityDefaultPropertiesManager.getInstance().getDefaultCoating(type, selectedAsset.manufacturer.name, selectedAsset.objectType._id);
                                    }
                                }
                            }

                            // The object has expected parts ()
                            if (selectedAsset.customization.expectedParts && selectedAsset.customization.expectedParts.length > 0) {
                                // Default white coating for Doors, Cabinet and TopBottom
                                /*if (type === 'Caisson' || type === 'Porte' || type === 'HautBas') {
                                    selectedAsset.customization.parts[i].id_coating = '5f21626db36b241d008438de';
                                }*/
                                // Default customizationGroup assignement
                                if (type === 'Porte') {
                                    for (let j = 0; j < selectedAsset.customization.expectedParts.length; j++) {
                                        if (selectedAsset.customization.expectedParts[j].name === type) {
                                            for (let k = 0; k < selectedAsset.customization.expectedParts[j].groups.length; k++) {
                                                if (selectedAsset.customization.expectedParts[j].groups[k].name === 'TREND +' ||
                                                    selectedAsset.customization.expectedParts[j].groups[k].name === 'TREND CUBE' ||
                                                    selectedAsset.customization.expectedParts[j].groups[k].name === 'TREND + JET' ||
                                                    selectedAsset.customization.expectedParts[j].groups[k].name === 'TREND EDGE') {
                                                    customization.customizationGroup = selectedAsset.customization.expectedParts[j].groups[k];
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            customization.parts.push({ name: selectedAsset.customization.parts[i].name, id_coating: id_coating });
                        }
                        // Sort Customizations using name key
                        customization.parts.sort(function(a, b) {
                            if (a.name < b.name) {
                                return (-1)
                            }
                            else {
                                return (1);
                            }
                        });
                    }

                    // Save customization expected parts if any
                    if (selectedAsset.customization.expectedParts) {
                        for (i = 0; i < selectedAsset.customization.expectedParts.length; i++) {
                            customization.expectedParts.push(selectedAsset.customization.expectedParts[i]);
                        }
                        customization.expectedParts.sort(function(a, b) {
                            if (a.name < b.name) {
                                return (-1)
                            }
                            else {
                                return (1);
                            }
                        });
                    }
                }

                if (customization.parts.length === 0) {
                    customization = undefined;
                }

                // Mouchard
                if (!selectedAsset.objectType) {
                    throw("Object with unknown object type " + selectedAsset._id);
                }
                if (!selectedAsset.configs[choosenIndex - 1]) {
                    throw("Object with no configs " + selectedAsset._id);
                }
                if (!selectedAsset.configs[choosenIndex - 1].dimensions) {
                    throw("Object with no dimensions " + selectedAsset._id);
                }
                if (selectedAsset.configs[choosenIndex - 1].dimensions.width === 0 || selectedAsset.configs[choosenIndex - 1].dimensions.length === 0 || selectedAsset.configs[choosenIndex - 1].dimensions.height === 0) {
                    throw "Objet with dimensions set to 0 " + selectedAsset._id;
                }
                // Mouchard

                entity = Savane.EntityFactory.createArrangementObject(selectedAsset._id,
                    selectedAsset.objectType._id,
                    selectedAsset.manufacturer,
                    selectedAsset.retailer,
                    selectedAsset.styles,
                    selectedAsset.configs[choosenIndex - 1].dimensions.width,
                    selectedAsset.configs[choosenIndex - 1].dimensions.length,
                    selectedAsset.configs[choosenIndex - 1].dimensions.height,
                    selectedAsset.choosenIndex,
                    coating,
                    selectedAsset.stretchability,
                    customization,
                    URL);

                if (selectedAsset.objectTypeConfig !== undefined) {
                    if (selectedAsset.objectTypeConfig.hasLight) {
                        entity.lightOn = false;
                        entity.lightOff = false;
                        entity.temperature = SceneConstants.LightTemperature.normal;
                    }
                    if (selectedAsset.objectTypeConfig.coatingAllowed) {
                        entity.coatingAllowed = true;
                    } else {
                        entity.coatingAllowed = false;
                    }
                }
                break;
            }
            case _ASSET_TYPE.COATINGS: {
                // Then create the URL of the preview to load
                let URL = ARRMGR_MEDIA_URL + 'coatings/' + selectedAsset._id + '/medias/previews/preview' + selectedAsset.choosenIndex + '-sm.webp';
                if (!selectedAsset.coatingType) {
                    throw("Coating with unknown coating type " + selectedAsset._id);
                }
                entity = Savane.EntityFactory.createArrangementObject(selectedAsset._id,
                    selectedAsset.coatingType._id,
                    selectedAsset.manufacturer,
                    selectedAsset.retailer,
                    0,
                    800,
                    800,
                    800,
                    selectedAsset.choosenIndex,
                    coating,
                    selectedAsset.stretchability,
                    selectedAsset.customization,
                    URL);
                break;
            }
            case _ASSET_TYPE.JOINERIES:
            case _ASSET_TYPE.TECHNICAL_ELEMENTS:
            case _ASSET_TYPE.DECORATED_WALLS:
                // Let's search for the correct asset to display as preview
                let mediaIdx;

                // Search for a small preview first
                for (mediaIdx = 0; mediaIdx < selectedAsset.medias.length; mediaIdx++) {
                    if (selectedAsset.medias[mediaIdx].indexOf("preview1-sm.") !== -1) {
                        break;
                    }
                }

                // If not found, then search for the normal preview
                if (mediaIdx === selectedAsset.medias.length) {
                    for (mediaIdx = 0; mediaIdx < selectedAsset.medias.length; mediaIdx++) {
                        if (selectedAsset.medias[mediaIdx].indexOf("preview1.") !== -1) {
                            break;
                        }
                    }
                }
                // Then create the URL of the preview to load
                let URL = ARRMGR_URL + '.' + selectedAsset.medias[mediaIdx] + '?apikey=' + ARRMGR_TOKEN;

                // Return the correct entity
                switch (assetType) {
                    case _ASSET_TYPE.TECHNICAL_ELEMENTS:
                        // Create the arrangement object to pass to the DragComponentState
                        entity = Savane.EntityFactory.createArrangementObject(selectedAsset._id,
                            selectedAsset.technicalElementType._id,
                            selectedAsset.manufacturer,
                            selectedAsset.retailer,
                            0,
                            800,
                            800,
                            800,
                            1,
                            coating,
                            selectedAsset.stretchability,
                            selectedAsset.customization,
                            URL);
                        break;

                    case _ASSET_TYPE.JOINERIES:
                        // Create the arrangement object to pass to the DragComponentState
                        entity = Savane.EntityFactory.createArrangementObject(selectedAsset._id,
                            selectedAsset.joineryType._id,
                            selectedAsset.manufacturer,
                            selectedAsset.retailer,
                            0,
                            800,
                            800,
                            800,
                            1,
                            coating,
                            selectedAsset.stretchability,
                            selectedAsset.customization,
                            URL);
                        break;

                    case _ASSET_TYPE.DECORATED_WALLS:
                        // Create the arrangement object to pass to the DragComponentState
                        entity = Savane.EntityFactory.createArrangementObject(selectedAsset._id,
                            selectedAsset.wallType._id,
                            selectedAsset.manufacturer,
                            selectedAsset.retailer,
                            0,
                            800,
                            800,
                            800,
                            1,
                            coating,
                            selectedAsset.stretchability,
                            selectedAsset.customization,
                            URL);
                        break;
                }
                break;
        }

        entity.name = selectedAsset.name;

        // Do we have to add handles to the arrangement object ?
        let addHandles = false;

        // If the assets has zones
        if (selectedAsset.zones) {
            // Add zones as child of entity
            for (i = 0; i < selectedAsset.zones.length; i++) {
                let zoneDatas = selectedAsset.zones[i];
                let length = Math.abs(zoneDatas.max.x - zoneDatas.min.x);
                let width = Math.abs(zoneDatas.max.y - zoneDatas.min.y);
                let height = Math.abs(zoneDatas.max.z - zoneDatas.min.z);
                let newZone = Savane.EntityFactory.createArrangementZone(parseInt(zoneDatas.type, 10), width, length, height, zoneDatas.offset, zoneDatas.up, zoneDatas.forward, zoneDatas.right);

                if (zoneDatas.data && zoneDatas.data != null) {
                    newZone.data = zoneDatas.data;
                }
                entity.addChild(newZone);

                let position = math.vec3.create();
                // We retrieve the position of the box thanks to min and max. Offset acts as an anchor now.
                //math.vec3.set(position, zoneDatas.offset.x, zoneDatas.offset.y, zoneDatas.offset.z);
                math.vec3.set(position, zoneDatas.min.x + (zoneDatas.max.x - zoneDatas.min.x) / 2, zoneDatas.min.y + (zoneDatas.max.y - zoneDatas.min.y) / 2, zoneDatas.min.z + (zoneDatas.max.z - zoneDatas.min.z) / 2);
                newZone.localPosition = position;

                // If the zone is for handles, then we will have to create them right after this loop
                if (newZone.isHandleZone()) {
                    addHandles = true;
                }
            }
        }

        // If the asset has a sketchblock
        if (selectedAsset.sketchBlock !== null && selectedAsset.sketchBlock !== undefined) {
            // Add sketchblock as child of entity
            let sketchBlock = Savane.EntityFactory.createSketchBlock(selectedAsset.sketchBlock._id,
                selectedAsset.sketchBlock.dimensions.width,
                selectedAsset.sketchBlock.dimensions.length,
                selectedAsset.sketchBlock.dimensions.height);

            entity.addChild(sketchBlock);

            //FIXME *****Temporary decal using a predefined rules
            let position = math.vec3.create();
            switch (selectedAsset.objectType._id) {
                case SceneConstants.ArrangementType.kitchenFurnitureBottom:
                case SceneConstants.ArrangementType.kitchenFurnitureColumn:
                    math.vec3.set(position, 0, (entity.width - sketchBlock.width) * 0.5, (-entity.height + sketchBlock.height) * 0.5);  //Back-bottom
                    break;

                case SceneConstants.ArrangementType.kitchenFurnitureTop:
                    math.vec3.set(position, 0, (entity.width - sketchBlock.width) * 0.5, (entity.height - sketchBlock.height) * 0.5);   //Back-top
                    break;
            }
            //*******
            position = math.vec3.create();

            math.vec3.set(position, 0, (sketchBlock.width - entity.width) / 2, 0);
            sketchBlock.transform.localPosition = position;
        }

        entity._objectTypeConfig = selectedAsset.objectTypeConfig;

        // Config to parse
        let config;
        // Property within the config
        let prop;

        // Retrieve config from objectType attach to the asset
        if (selectedAsset.objectType !== undefined) {
            config = selectedAsset.objectType.config;
        }
        if (config === undefined) {
            config = {};
        }
        if (selectedAsset.objectType !== undefined) {
            //Read super conf to file empty params
            for (prop in selectedAsset.objectType.superConfig) {
                if (config[prop] === undefined || config[prop] === null) {
                    config[prop] = selectedAsset.objectType.superConfig[prop];
                }
            }
        }

        // Proceed configuration by parsing all config properties in the array
        for (prop in config) {
            if (config[prop] !== null) {
                switch (prop) {
                    // Object is anchored
                    case 'anchor':
                        let newAnchor = math.vec3.create();
                        math.vec3.set(newAnchor, config[prop].x, config[prop].y, config[prop].z);
                        entity.anchor = newAnchor;
                        break;

                    // Object can be stacked
                    case 'stackable':
                        entity.stackable = config[prop];
                        break;

                    // Floor height parameter
                    case 'height':
                        // Very ugly, in case anchor is active and anchor y === -1 the floor height of the object is determined from the property
                        if (config.anchor && config.anchor.y < 0) {
                            switch(config.anchor.y) {
                                // middle
                                case -1:
                                    entity.floorHeight = (config[prop] * 10) - (entity.height / 2);
                                    break;

                                // bottom at
                                case -2:
                                    entity.floorHeight = (config[prop] * 10);
                                    break;

                                // top at
                                case -3:
                                    entity.floorHeight = (config[prop] * 10) - entity.height;
                                    break;
                            }
                        }
                        else {
                            // Else we create a box collider instead

                            // Check if the object already has a box collider
                            let zones = entity.getZones(SceneConstants.ArrangementZoneType.collider);

                            // No box ?
                            if (zones.length <= 0) {
                                // zone height
                                let zoneHeight = config[prop] * 10;
                                // Create a zone, height of the zone is in the parameter (this will create a collision box from the floor to a specific height)
                                let zone = Savane.EntityFactory.createArrangementZone(SceneConstants.ArrangementZoneType.collider, entity.width, entity.length, Math.abs(zoneHeight));
                                // Decal zone
                                let vecPos = math.vec3.create();
                                // yPos of the zone bottom of the object as default
                                let yPos = (Math.abs(zoneHeight) - entity.height) * 0.5;

                                // If negative height then the box is at the top of the object
                                if (zoneHeight < 0) {
                                    yPos = -yPos;
                                }

                                // Create the position of the collider box
                                math.vec3.set(vecPos, 0, 0, yPos);
                                zone.localPosition = vecPos;
                                // Add to entity
                                entity.addChild(zone);
                            }
                        }
                        break;
                }
            }
        }

        if (assetType === _ASSET_TYPE.ARRANGEMENTS && selectedAsset.type !== 'asset') {
            loadExtraAssets = false;
        }

        // Do we have to load extra assets linked to the current asset ? (handles, coatings)
        if (loadExtraAssets) {
            // We will force a serie of async tasks to be synced
            async.series(
                [
                    // Step one, deal with customizations if any
                    function(serialCallback) {
                        if (entity.customization !== undefined) {
                            async.each(entity.customization.parts, function(item, customizationCallback) {
                                getAsset(_ASSET_TYPE.COATINGS, item.id_coating, null, function(coatingAsset) {
                                    if (!coatingAsset._id) {
                                        // asset manager is down, we receive an empty data dict
                                        return customizationCallback();
                                    }
                                    // Create new coating to apply for this customization part of the kitchen furniture
                                    let hangType = Coating.HangType.door;

                                    if (item.name.includes("Porte")) {
                                        hangType = Coating.HangType.door;
                                    }
                                    if (item.name.includes("Caisson")) {
                                        hangType = Coating.HangType.box;
                                    }
                                    if (item.name.includes("Gouttiere")) {
                                        hangType = Coating.HangType.gutter;
                                    }
                                    if (item.name.includes("HautBas")) {
                                        hangType = Coating.HangType.topBottom;
                                    }
                                    if (item.name.includes("Evier")) {
                                        hangType = Coating.HangType.sink;
                                    }
                                    if (item.name.includes("Mitigeur")) {
                                        hangType = Coating.HangType.mixer;
                                    }
                                    if (item.name.includes("Panneau")) {
                                        hangType = Coating.HangType.panel;
                                    }
                                    if (item.name.includes("Etagere")) {
                                        hangType = Coating.HangType.shelf;
                                    }
                                    if (item.name.includes("Fond")) {
                                        hangType = Coating.HangType.back;
                                    }
                                    if (item.name.includes("Chant")) {
                                        hangType = Coating.HangType.front;
                                    }
                                    if (item.name.includes("Bouton")) {
                                        hangType = Coating.HangType.button;
                                    }
                                    if (item.name.includes("Plaque")) {
                                        hangType = Coating.HangType.flatpiece;
                                    }

                                    let coatingComponent;

                                    if (coatingAsset.colors !== undefined) {
                                        coatingComponent = new Coating(coatingAsset._id, coatingAsset.manufacturer, coatingAsset.retailer, hangType, [coatingAsset.colors], coatingAsset.randomization, coatingAsset.isHpFloorGenerator ? coatingAsset.hpFloorGeneratorSettings : undefined, '');
                                    }
                                    else {
                                        // Old structure with colors in configs
                                        coatingComponent = new Coating(coatingAsset._id, coatingAsset.manufacturer, coatingAsset.retailer, hangType, coatingAsset.configs, coatingAsset.randomization, coatingAsset.isHpFloorGenerator ? coatingAsset.hpFloorGeneratorSettings : undefined, '');
                                    }
                                    entity.addComponent(coatingComponent);
                                    customizationCallback();
                                }.bind(this));
                            },
                                function(err) {
                                    serialCallback(null, null);
                                });
                        }
                        else {
                            serialCallback(null, null);
                        }
                    },
                    // Step two, load plinth coating to attach it to the entity if the object has plinthes
                    function(serialCallback) {
                        // Load plinth coating if necessary
                        if (isKitchenEntityWithPlinths(entity) && typeof EntityDefaultPropertiesManager !== 'undefined') {
                            getAsset(_ASSET_TYPE.COATINGS, EntityDefaultPropertiesManager.getInstance().getDefaultCoating("Plinthes", entity.manufacturer.name, entity.objectType), null, function(coatingAsset) {
                                // Create new coating to apply for plinths of the kitchen furniture
                                let coatingComponent;
                                if (coatingAsset.colors !== undefined) {
                                    coatingComponent = new Coating(coatingAsset._id, coatingAsset.manufacturer, coatingAsset.retailer, Coating.HangType.kitchenplinth, [coatingAsset.colors], coatingAsset.randomization, coatingAsset.isHpFloorGenerator ? coatingAsset.hpFloorGeneratorSettings : undefined, '');
                                }
                                else {
                                    coatingComponent = new Coating(coatingAsset._id, coatingAsset.manufacturer, coatingAsset.retailer, Coating.HangType.kitchenplinth, coatingAsset.configs, coatingAsset.randomization, coatingAsset.isHpFloorGenerator ? coatingAsset.hpFloorGeneratorSettings : undefined, '');
                                }
                                entity.addComponent(coatingComponent);
                                serialCallback(null, null);
                            }.bind(this));
                        }
                        else {
                            serialCallback(null, null);
                        }
                    },
                    // Step three, load handle object if the entity has handles
                    function(serialCallback) {
                        // Do we have to add handles
                        if (addHandles && typeof EntityDefaultPropertiesManager !== 'undefined') {
                            // Handle asset to create, get it from default properties first
                            let handleModeleId = EntityDefaultPropertiesManager.getInstance().getDefaultHandle(selectedAsset.manufacturer.name, selectedAsset.objectType._id);
                            // Handle side will be center as default
                            let handleSide = (selectedAsset.handleSide !== undefined ? selectedAsset.handleSide : 0);

                            // If there is a handle modele
                            if (handleModeleId !== '') {
                                // Load it
                                getAsset(_ASSET_TYPE.ARRANGEMENTS, handleModeleId, null, function(handleAsset) {
                                    let handleModele = handleAsset;

                                    if (handleModele !== null) {
                                        // Create an entity thanks to this loaded model
                                        createAssetEntity(_ASSET_TYPE.ARRANGEMENTS, handleModele, true, function(handleEntity) {
                                            // Populate the arrangement with the loaded handle
                                            entity.populateWithHandles(handleEntity, handleSide);
                                            serialCallback(null, null);
                                        }.bind(this));
                                    }
                                    else {
                                        serialCallback(null, null);
                                    }
                                }.bind(this));
                            }
                            else {
                                // Default initialisation if no handle
                                entity.handleSide = handleSide;
                                entity.handleAsset = handleModeleId;
                                serialCallback(null, null);
                            }
                        }
                        else {
                            serialCallback(null, null);
                        }
                    },
                    // Final step, call the top level callback to terminate the loading
                    function(serialCallback) {
                        // Fire creation event
                        Savane.eventsManager.instance.dispatch(EVENTS.ARRANGEMENT_CREATED, entity);

                        // Call callback when done to inform entity created
                        callback(entity);

                        serialCallback(null, null);
                    }],
                function(err, results) {

                });
        }
        else {
            if (assetType === _ASSET_TYPE.ARRANGEMENTS) {
                switch(selectedAsset.type) {
                    case 'composition':
                        let groupEntity = Savane.EntityFactory.createEmptyArrangementGroup();

                        groupEntity.arrangementGroupType = selectedAsset.type;

                        let totalLength = 0;
                        let totalHeight = 0;
                        for (let i = 0 ; i < selectedAsset.configs.length ; i++) {
                            totalLength += selectedAsset.configs[i].dimensions.width;
                            totalHeight += selectedAsset.configs[i].dimensions.height;
                        }
                        let pos;
                        if (entity.anchor[2] !== 1) {
                            pos = -totalLength / 2;
                        }
                        else {
                            pos = totalHeight / 2;
                        }
                        for (let i = 0 ; i < selectedAsset.configs.length ; i++) {
                            let clonedEntity = Savane.EntityFactory.cloneArrangementObject(entity, true);
                            let URL = getMediaArrangementUrl() + selectedAsset._id + '/medias/previews/top_view' + (i + 1) + '.webp';

                            let comp = clonedEntity.getComponent(ComponentConstants.ComponentType.RendererTopView);
                            comp.urlTopview = URL;
                            clonedEntity.colorId = i + 1;
                            if (entity.anchor[2] !== 1) {
                                clonedEntity.transform.localMatrix[12] = pos + (selectedAsset.configs[i].dimensions.width / 2);
                                pos += selectedAsset.configs[i].dimensions.width;
                            }
                            else {
                                clonedEntity.transform.localMatrix[14] = pos - (selectedAsset.configs[i].dimensions.height / 2);
                                pos -= selectedAsset.configs[i].dimensions.height;
                            }
                            if (clonedEntity._originalLength) {
                                clonedEntity._originalLength = selectedAsset.configs[i].dimensions.width;
                            }
                            if (clonedEntity._originalWidth) {
                                clonedEntity._originalWidth = selectedAsset.configs[i].dimensions.length;
                            }
                            if (clonedEntity._originalHeight) {
                                clonedEntity._originalHeight = selectedAsset.configs[i].dimensions.height;
                            }
                            clonedEntity._length = selectedAsset.configs[i].dimensions.width;
                            clonedEntity._width = selectedAsset.configs[i].dimensions.length;
                            clonedEntity._height = selectedAsset.configs[i].dimensions.height;

                            groupEntity.addChild(clonedEntity);
                        }

                        groupEntity.recenter();

                        entity = groupEntity;
                        break;
                }
            }

            // Fire creation event
            Savane.eventsManager.instance.dispatch(EVENTS.ARRANGEMENT_CREATED, entity);

            // Call callback when done to inform entity created
            callback(entity);
        }
    }
};

