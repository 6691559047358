var SupportsPassive = false;
try {
  var opts = Object.defineProperty({}, 'passive', {
    get: function() {
        SupportsPassive = true;
    }
  });
  window.addEventListener("testPassive", () => {}, opts);
  window.removeEventListener("testPassive", () => {}, opts);
} catch (e) {}
export {SupportsPassive}

export * from './Init';
export * from './AssetGroups';
export * from './Assets';
export * from './Coatings';
export * from './Constants';
export * from './Kitchen';
export * from './Pinboards';
export * from './PopulatedObjects';
export * from './Tags';
export * from './Templates';
