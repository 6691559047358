import { ARRMGR_URL, ARRMGR_API_KEY } from './Init';

import { HttpService } from '@rhinov/savane-js';

/**
 * Return all coating types
 * @param {string[]} filters
 * @param {function} callback
 */
export let getCoatingTypes = (filters: Array<string>, callback: CallableFunction) => {
    let params = '';

    if (filters !== null) {
        for (let i = 0; i < filters.length; i++) {
            if (i > 0) {
                params += '&';
            }
            params += filters[i];
        }
        params += '&';
    }

    HttpService.get(ARRMGR_URL + 'api/v1/coatingTypes?' + params + ARRMGR_API_KEY)
        .then(function(response) {
            callback(response);
        });
};

/**
 * Return all coatings
 * @param {string[]} filters
 * @param {function} callback
 */
export let getCoatings = (filters: Array<string>, callback: CallableFunction) => {
    let params = '';

    if (filters !== null) {
        for (let i = 0; i < filters.length; i++) {
            if (i > 0) {
                params += '&';
            }
            params += filters[i];
        }
        params += '&';
    }

    HttpService.get(ARRMGR_URL + 'api/v1/coatings?' + params + ARRMGR_API_KEY)
        .then(function(response) {
            callback(response);
        });
};

/**
 * Return one coating
 * @param {string} id
 * @param {function} callback
 */
export let getCoating = (id: string, callback: CallableFunction) => {
    HttpService.get(ARRMGR_URL + 'api/v1/coatings/' + id + '?' + ARRMGR_API_KEY)
        .then(function(response) {
            callback(response);
        });
};