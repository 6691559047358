declare let Savane;

import { ARRMGR_URL, ARRMGR_API_KEY } from './Init';

import { HttpService } from '@rhinov/savane-js';

export let getPinboards = (pub: boolean, email: string, page: string, limit: string, callback: CallableFunction) => {
    let url = ARRMGR_URL + 'api/v1/pinboards?' + ARRMGR_API_KEY;
    if (pub) {
        url += '&q.public.eq=true';
    } else {
        url += '&q.owner.email.eq=' + email;
    }
    if (page && limit) {
        url += '&sort=name&page=' + page + '&limit=' + limit;
    }
    HttpService.get(url)
        .then(
            function(response) {
                callback(response.data);
            },
            function() {
                callback(null);
            }
        );
};

export let createPinboard = (email: string, name: string, pub: boolean, callback: CallableFunction) => {
    let userIdUrl = ARRMGR_URL + 'services/users/?' + ARRMGR_API_KEY + '&q.email.eq=' + email + "&fields=_id";
    HttpService.get(userIdUrl)
        .then(
            function(response) {
                if(response.status === 204){
                    callback(null);
                    return;
                }
                let url = ARRMGR_URL + 'api/v1/pinboards?' + ARRMGR_API_KEY;
                let payload = {
                    'name': name,
                    'owner': response.data.resources[0]._id,
                    'public': pub
                }
                HttpService.post(url, payload)
                    .then(
                        function(response) {
                            callback(response.data);
                        },
                        function() {
                            callback(null);
                        }
                    )
            },
            function() {
                callback(null);
            }
        );
};

export let editPinboard = (id: string, name: string, callback: CallableFunction) => {
    let url = ARRMGR_URL + 'api/v1/pinboards/' + id + '?' + ARRMGR_API_KEY;
    let payload = {
        'name': name
    }
    HttpService.patch(url, payload)
        .then(
            function(response) {
                callback(response.data);
            },
            function() {
                callback(null);
            }
        );
};

export let deletePinboard = (id: string, callback: CallableFunction) => {
    let url = ARRMGR_URL + 'api/v1/pinboards/' + id + '?' + ARRMGR_API_KEY;
    HttpService.delete(url)
        .then(
            function(response) {
                callback(response.data);
            },
            function() {
                callback(null);
            }
        );
};

export let getResourcesPinboards = (idsListStr: string, email: string, callback: CallableFunction) => {
    Savane.eventsManager.instance.dispatch(Savane.Events.STOP_UPDATING_ENVS);
    let url = ARRMGR_URL + 'api/v1/pinboards?q.items.in=' + idsListStr + '&q.owner.email.eq=' + email;
    url += '&' + ARRMGR_API_KEY;
    HttpService.get(url)
        .then(
            function(response) {
                callback(response.data);
            },
            function() {
                callback(null);
            }
        );
};

export let addToPinboard = (asset: string, pinboard: string, callback: CallableFunction) => {
    Savane.eventsManager.instance.dispatch(Savane.Events.STOP_UPDATING_ENVS);
    let url = ARRMGR_URL + 'api/v1/pinboards/' + pinboard + '/assets/' + asset + '?' + ARRMGR_API_KEY;
    HttpService.put(url, null)
        .then(
            function(response) {
                callback(response.data);
            },
            function() {
                callback(null);
            }
        );
};

export let removeFromPinboard = (asset: string, pinboard: string, callback: CallableFunction) => {
    Savane.eventsManager.instance.dispatch(Savane.Events.STOP_UPDATING_ENVS);
    let url = ARRMGR_URL + 'api/v1/pinboards/' + pinboard + '/assets/' + asset + '?' + ARRMGR_API_KEY;
    HttpService.delete(url)
        .then(
            function(response) {
                callback(response.data);
            },
            function() {
                callback(null);
            }
        );
};

