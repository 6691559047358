declare let EntityDefaultPropertiesManager;

import { _ASSET_TYPE } from './Constants';
import { getAssets, createAssetEntity } from './Assets';

import { ArrangementObject, Coating, ComponentConstants } from '@rhinov/savane-js'

export let isKitchenEntityWithPlinths = (entity: ArrangementObject) => {
    if ((!entity.objectTypeConfig) || (!entity.objectTypeConfig.plinthHeight)) {
        return(false);
    }
    return (true);
}

//
// Change an object list (of kitchen objects to another kitchen style
//
export let changeKitchenStyle = (entityList: Array<ArrangementObject>, newStyle: string, newDoorColor: string, callback: CallableFunction) => {
    let allObjectsIds = entityList.map(function(item) { return item.objectId });
    let toReturn: Array<any> = [];

    getAssets(_ASSET_TYPE.ARRANGEMENTS, '&fields=_id,pipeline.originalModel,objectTypeConfig&q._id.in=' + allObjectsIds, function(result) {
        let originalModelsList: Array<any> = [];
        let resourcesAM = result.data.resources as Array<any>;

        for (let i = 0 ; i < allObjectsIds.length ; i++) {
            let idx = resourcesAM.findIndex((x) => x._id === allObjectsIds[i]);

            // idx === -1 impossible here, the ressources must be found whatever happens

            // AM ressource has an original model ?
            if (resourcesAM[idx].pipeline.originalModel) {
                // Yes store it
                originalModelsList.push(resourcesAM[idx].pipeline.originalModel._id);
                toReturn.push({current_object_id: allObjectsIds[i], original_model_id: resourcesAM[idx].pipeline.originalModel._id, new_object_id: null, new_object_entity: null, filled: false});
            }
            else {
                // No, then the original model is himself
                originalModelsList.push(allObjectsIds[i]);
                toReturn.push({current_object_id: allObjectsIds[i], original_model_id: allObjectsIds[i], new_object_id: null, new_object_entity: null, filled: false});
            }
        }

        let search = '&q.pipeline.originalModel.in=';
        if (newStyle === 'IX_blank') {
            search = '&q._id.in=';
        }

        getAssets(_ASSET_TYPE.ARRANGEMENTS,
                                        '&fields=kind,_id,pipeline.originalModel,marketName,price,genericName,name,type,randomization,dimensions,displayDimensions,sketchBlock,marketLink,configs,colors,stretchability,objectType,manufacturer,retailer,rse,pipeline.state,pipeline.modelState,styles,objectTypeConfig,zones,customization,universes,pipeline.unrealState' +
                                         search + originalModelsList +
                                         '&q.objectTypeConfig.doorType.eq=' + newStyle, function(result) {
            let resourcesAM2 = result.data.resources;

            for (let j = 0 ; j < toReturn.length ; j++) {
                let idx = resourcesAM2.findIndex((x) => {
                    if (x.pipeline.originalModel) {
                        return(toReturn[j].original_model_id === x.pipeline.originalModel._id);
                    }
                    else {
                        return(toReturn[j].original_model_id === x._id);
                    }
                });

                // Original model id found in AM ressources ?
                if (idx !== -1) {
                    // Yes store it
                    toReturn[j].new_object_id = resourcesAM2[idx]._id;
                }
                else {
                    // No consider the ressource is itself
                    toReturn[j].new_object_id = toReturn[j].original_model_id;
                    toReturn[j].filled = true;
                }
            }

            for (let j = 0 ; j < toReturn.length ; j++) {
                let idx = resourcesAM2.findIndex((x) => x._id === toReturn[j].new_object_id);

                // No ressource found to replace the original ressource, cintinue loop
                if (idx === -1) {
                    continue;
                }

                // Set coating default values for further entity creation
                if (typeof EntityDefaultPropertiesManager !== 'undefined') {
                    let entity = entityList[j];
                    let components = entity.getComponents(ComponentConstants.ComponentType.Coating) as Array<Coating>;

                    for (let k = 0 ; k < components.length ; k++) {
                        if (components[k].hangType !== Coating.HangType.door) {
                            EntityDefaultPropertiesManager.getInstance().setDefaultCoatingByHangtype(components[k].hangType, components[k].coatingId, entity.manufacturer.name, entity.objectType);
                        }
                        else {
                            if (newDoorColor) {
                                EntityDefaultPropertiesManager.getInstance().setDefaultCoatingByHangtype(components[k].hangType, newDoorColor, entity.manufacturer.name, entity.objectType);
                            }
                            else {
                                EntityDefaultPropertiesManager.getInstance().setDefaultCoatingByHangtype(components[k].hangType, components[k].coatingId, entity.manufacturer.name, entity.objectType);
                            }
                        }
                    }

                    if (entity.handleAsset) {
                        EntityDefaultPropertiesManager.getInstance().setDefaultHandle(entity.handleAsset, entity.manufacturer.name, entity.objectType);
                    }
                }

                // Transform AM ressource to entity savane
                resourcesAM2[idx].handleSide = entityList[idx].handleSide;
                createAssetEntity(_ASSET_TYPE.ARRANGEMENTS, resourcesAM2[idx], true, function(entity) {
                    let idx = toReturn.findIndex((x) => x.new_object_id === entity.objectId && x.new_object_entity === null);

                    toReturn[idx].new_object_entity = entity;
                    toReturn[idx].filled = true;

                    let l = 0
                    for (; l < toReturn.length ; l++) {
                        // End or not end of entity creations ?
                        if (!toReturn[l].filled) {
                            break;
                        }
                    }

                    if (l === toReturn.length) {
                        callback(toReturn);
                    }
                });
            }
        });
    });
}

