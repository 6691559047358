declare let Savane;

import { ARRMGR_URL, ARRMGR_API_KEY } from './Init';

import { HttpService } from '@rhinov/savane-js';

/**
 * Push a new asset group
 *
 * @param name
 * @param rhinovFormat
 * @param callback
 */
export let commitAssetGroup = (name: string, rhinovFormat: string, callback: CallableFunction) => {
    HttpService.post(ARRMGR_URL + 'api/v1/assets_groups?' + ARRMGR_API_KEY, {
            name: name,
            rhinovFormat: rhinovFormat
        })
        .then(
            function(response) {
                //success
                callback(response);
            },
            function(response) {
                //error
                callback(response);
            }
        );
};

export let postAssetGroupPreview = (templateId: string, blob: Blob, callback: CallableFunction) => {
    let fd = new FormData();

    fd.append('file', blob);
    HttpService.put(ARRMGR_URL + 'api/v1/assets_groups/' + templateId + "/medias/previews/preview.jpg?" + ARRMGR_API_KEY, fd)
        .then(
            function(response) {
                //successCallback
                callback(response);
            },
            function(response) {
                //error
                callback(response);
            });
};

/**
 * Modify an asset group
 *
 * @param name
 * @param rhinovFormat
 * @param callback
 */
export let modifyAssetGroup = (id: string, name: string, rhinovFormat: string, callback: CallableFunction) => {
    HttpService.patch(ARRMGR_URL + 'api/v1/assets_groups/' + id + '?' + ARRMGR_API_KEY,
        {
            name: name,
            rhinovFormat:rhinovFormat

        })
        .then(
            function(response) {
                //success
                callback(response);
            },
            function(response) {
                //error
                callback(response);
            }
        );
};

/**
 * Retrieve asset datas
 * @param assetType
 * @param id
 * @param apiKey
 * @param callback
 * @returns {*}
 */
export let getAssetGroupByName = (name: string, callback: CallableFunction) => {
    HttpService.get(ARRMGR_URL + 'api/v1/assets_groups?q.name.eq=' + encodeURIComponent(name) + '&' + ARRMGR_API_KEY)
        .then(
            function(response) {
                if (callback)
                    callback(response);
            },
            function(response) {
                if (callback)
                    callback(response);
            }
        );
};

export let getAssetsGroupsLists = (pub: boolean, email: string, page: string, limit: string, callback: CallableFunction) => {
    let url = ARRMGR_URL + 'api/v1/assetsGroupsLists?' + ARRMGR_API_KEY;
    if (pub) {
        url += '&q.public.eq=true';
    } else {
        url += '&q.owner.email.eq=' + email;
    }
    if (page && limit) {
        url += '&sort=name&page=' + page + '&limit=' + limit;
    }
    HttpService.get(url)
        .then(
            function(response) {
                callback(response.data);
            },
            function() {
                callback(null);
            }
        );
};

export let isUserAllowedToManageAssetsGroupsList = (email: string, callback: CallableFunction) => {
    let user = ARRMGR_URL + 'services/users/?' + ARRMGR_API_KEY + '&q.email.eq=' + email + '&q.groups.in=58c137ca087d8449000f53e2&fields=_id';
    HttpService.get(user)
        .then(
            function(response) {
                callback(response.status === 200);
            },
            function() {
                callback(null);
            }
        );
};

export let createAssetsGroupsList = (email: string, name: string, pub: boolean, callback: CallableFunction) => {
    let userIdUrl = ARRMGR_URL + 'services/users/?' + ARRMGR_API_KEY + '&q.email.eq=' + email + "&fields=_id";
    HttpService.get(userIdUrl)
        .then(
            function(response) {
                let url = ARRMGR_URL + 'api/v1/assetsGroupsLists?' + ARRMGR_API_KEY;
                let payload = {
                    'name': name,
                    'owner': response.data.resources[0]._id,
                    'public': pub
                }
                HttpService.post(url, payload)
                    .then(
                        function(response) {
                            callback(response.data);
                        },
                        function() {
                            callback(null);
                        }
                    )
            },
            function() {
                callback(null);
            }
        );
};

export let editAssetsGroupsList = (id: string, name: string, callback: CallableFunction) => {
    let url = ARRMGR_URL + 'api/v1/assetsGroupsLists/' + id + '?' + ARRMGR_API_KEY;
    let payload = {
        'name': name
    }
    HttpService.patch(url, payload)
        .then(
            function(response) {
                callback(response.data);
            },
            function() {
                callback(null);
            }
        );
};

export let deleteAssetsGroupsList = (id: string, callback: CallableFunction) => {
    let url = ARRMGR_URL + 'api/v1/assetsGroupsLists/' + id + '?' + ARRMGR_API_KEY;
    HttpService.delete(url)
        .then(
            function(response) {
                callback(response.data);
            },
            function() {
                callback(null);
            }
        );
};

export let getResourcesAssetsGroupsList = (idsListStr: string, email: string, callback: CallableFunction) => {
    Savane.eventsManager.instance.dispatch(Savane.Events.STOP_UPDATING_ENVS);
    let url = ARRMGR_URL + 'api/v1/assetsGroupsLists?q.items.in=' + idsListStr;// + '&q.owner.email.eq=' + email;
    url += '&' + ARRMGR_API_KEY;
    HttpService.get(url)
        .then(
            function(response) {
                callback(response.data);
            },
            function() {
                callback(null);
            }
        );
};

export let addToAssetsGroupsList = (asset: string, assetsGroupsLists: string, callback: CallableFunction) => {
    Savane.eventsManager.instance.dispatch(Savane.Events.STOP_UPDATING_ENVS);
    let url = ARRMGR_URL + 'api/v1/assetsGroupsLists/' + assetsGroupsLists + '/assetsGroups/' + asset + '?' + ARRMGR_API_KEY;
    HttpService.put(url, null)
        .then(
            function(response) {
                callback(response.data);
            },
            function() {
                callback(null);
            }
        );
};

export let removeFromAssetsGroupsList = (asset: string, assetsGroupsLists: string, callback: CallableFunction) => {
    Savane.eventsManager.instance.dispatch(Savane.Events.STOP_UPDATING_ENVS);
    let url = ARRMGR_URL + 'api/v1/assetsGroupsLists/' + assetsGroupsLists + '/assetsGroups/' + asset + '?' + ARRMGR_API_KEY;
    HttpService.delete(url)
        .then(
            function(response) {
                callback(response.data);
            },
            function() {
                callback(null);
            }
        );
};